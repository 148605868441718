import { createGlobalStyle } from 'styled-components'
import {minCol,maxCol} from "../../function/SizeCol"
import Icons24_32 from "../svg/24/Icons24px-32.svg"
import Icons24_31 from "../svg/24/Icons24px-31.svg"

import Light from '../fonts/Geologica-Light.woff2';
import Regular from '../fonts/Geologica-Regular.woff2';
import SemiBold from '../fonts/Geologica-SemiBold.woff2';
import ExtraBold from '../fonts/Geologica-ExtraBold.woff2';
import Black from '../fonts/Geologica-Black.woff2';

const GlobalStyles = createGlobalStyle`
    //Global Fonts  ********************************************
    @font-face {
        font-family: 'Geologica';
        font-style: normal; 
        font-weight: 300; 
        src: url("${Light}") format("woff2");
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        font-optical-sizing: auto;
    }
    @font-face {
        font-family: 'Geologica';
        font-style: normal;
        font-weight: 400;
        src: url("${Regular}") format("woff2");
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        font-optical-sizing: auto;
    }
    @font-face {
        font-family: 'Geologica';
        font-style: normal;
        font-weight: 600;
        src: url("${SemiBold}") format("woff2");
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        font-optical-sizing: auto;
    }
    @font-face {
        font-family: 'Geologica';
        font-style: normal;
        font-weight: 800;
        src: url("${ExtraBold}") format("woff2");
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        font-optical-sizing: auto;
    }

    @font-face {
        font-family: 'Geologica';
        font-style: normal; 
        font-weight: 900;
        src: url("${Black}") format("woff2");
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        font-optical-sizing: auto; 
    }
    
    //Global Cols  ********************************************
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }
    .container,.container-fluid,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
        width:100%;
        padding-right:var(--bs-gutter-x,.75rem);
        padding-left:var(--bs-gutter-x,.75rem);
        margin-right:auto; 
        margin-left:auto
    } 
    @media (min-width:${minCol.sm}) {
        .container,.container-sm {
            //max-width:540px
        }
    }
    @media (min-width:${minCol.md}) {
        .container,.container-md,.container-sm {
            //max-width:720px
        }
    }
    @media (min-width:${minCol.lg}) {
        .container,.container-lg,.container-md,.container-sm {
            //max-width:960px
        }
    }
    @media (min-width:${minCol.xl}) {
        .container,.container-lg,.container-md,.container-sm,.container-xl {
            //max-width:1140px
        }
    }
    @media (min-width:${minCol.xxl}) {
        .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
            max-width:1440px
        }
    }
    .row {
        --bs-gutter-x:1.5rem;
        --bs-gutter-y:0;
        display:flex;
        flex-wrap:wrap;
        margin-top:calc(-1 * var(--bs-gutter-y));
        
          margin-right:calc( -1 * var(--bs-gutter-x));
          margin-left:calc( -1 * var(--bs-gutter-x));
          @media(max-width: ${maxCol.sm}) {
             margin-right:calc(-.5 * var(--bs-gutter-x));
             margin-left:calc(-.5 * var(--bs-gutter-x)) 
          } 
    }
    .row>* {
        box-sizing:border-box;
        flex-shrink:0;
        width:100%;
        max-width:100%;
        
        padding-right:calc(var(--bs-gutter-x) ); 
        padding-left:calc(var(--bs-gutter-x) );
        @media(max-width: ${maxCol.sm}) {
          padding-right:calc(var(--bs-gutter-x) * .5);
          padding-left:calc(var(--bs-gutter-x) * .5);
        }
        margin-top:var(--bs-gutter-y)
    }
    .col{flex:1 0 0}
    .row-cols-auto>*{flex:0 0 auto;width:auto}
    .row-cols-1>*{flex:0 0 auto;width:100%}
    .row-cols-2>*{flex:0 0 auto;width:50%}
    .row-cols-3>*{flex:0 0 auto;width:33.3333333333%}
    .row-cols-4>*{flex:0 0 auto;width:25%}
    .row-cols-5>*{flex:0 0 auto;width:20%}
    .row-cols-6>*{flex:0 0 auto;width:16.6666666667%}
    .col-auto{flex:0 0 auto;width:auto}
    .col-1{flex:0 0 auto;width:8.33333333%}
    .col-2{flex:0 0 auto;width:16.66666667%}
    .col-3{flex:0 0 auto;width:25%}
    .col-4{flex:0 0 auto;width:33.33333333%}
    .col-5{flex:0 0 auto;width:41.66666667%}
    .col-6{flex:0 0 auto;width:50%}
    .col-7{flex:0 0 auto;width:58.33333333%}
    .col-8{flex:0 0 auto;width:66.66666667%}
    .col-9{flex:0 0 auto;width:75%}
    .col-10{flex:0 0 auto;width:83.33333333%}
    .col-11{flex:0 0 auto;width:91.66666667%}
    .col-12{flex:0 0 auto;width:100%}
    .offset-1{margin-left:8.33333333%}
    .offset-2{margin-left:16.66666667%}
    .offset-3{margin-left:25%}
    .offset-4{margin-left:33.33333333%}
    .offset-5{margin-left:41.66666667%}
    .offset-6{margin-left:50%}
    .offset-7{margin-left:58.33333333%}
    .offset-8{margin-left:66.66666667%}
    .offset-9{margin-left:75%}
    .offset-10{margin-left:83.33333333%} 
    .offset-11{margin-left:91.66666667%}
    @media (min-width:${minCol.sm}) { 
        .col-sm{flex:1 0 0}
        .row-cols-sm-auto>*{flex:0 0 auto;width:auto}
        .row-cols-sm-1>*{flex:0 0 auto;width:100%}
        .row-cols-sm-2>*{flex:0 0 auto;width:50%}
        .row-cols-sm-3>*{flex:0 0 auto;width:33.3333333333%}
        .row-cols-sm-4>*{flex:0 0 auto;width:25%}
        .row-cols-sm-5>*{flex:0 0 auto;width:20%}
        .row-cols-sm-6>*{flex:0 0 auto;width:16.6666666667%}
        .col-sm-auto{flex:0 0 auto;width:auto}
        .col-sm-1{flex:0 0 auto;width:8.33333333%}
        .col-sm-2{flex:0 0 auto;width:16.66666667%}
        .col-sm-3{flex:0 0 auto;width:25%}
        .col-sm-4{flex:0 0 auto;width:33.33333333%}
        .col-sm-5{flex:0 0 auto;width:41.66666667%}
        .col-sm-6{flex:0 0 auto;width:50%}
        .col-sm-7{flex:0 0 auto;width:58.33333333%}
        .col-sm-8{flex:0 0 auto;width:66.66666667%}
        .col-sm-9{flex:0 0 auto;width:75%}
        .col-sm-10{flex:0 0 auto;width:83.33333333%}
        .col-sm-11{flex:0 0 auto;width:91.66666667%}
        .col-sm-12{flex:0 0 auto;width:100%}
        .offset-sm-0{margin-left:0}
        .offset-sm-1{margin-left:8.33333333%}
        .offset-sm-2{margin-left:16.66666667%}
        .offset-sm-3{margin-left:25%}
        .offset-sm-4{margin-left:33.33333333%}
        .offset-sm-5{margin-left:41.66666667%}
        .offset-sm-6{margin-left:50%}
        .offset-sm-7{margin-left:58.33333333%}
        .offset-sm-8{margin-left:66.66666667%}
        .offset-sm-9{margin-left:75%}
        .offset-sm-10{margin-left:83.33333333%}
        .offset-sm-11{margin-left:91.66666667%}
    }
    @media (min-width:${minCol.md}) {
        .col-md{flex:1 0 0}
        .row-cols-md-auto>*{flex:0 0 auto;width:auto}
        .row-cols-md-1>*{flex:0 0 auto;width:100%}
        .row-cols-md-2>*{flex:0 0 auto;width:50%}
        .row-cols-md-3>*{flex:0 0 auto;width:33.3333333333%}
        .row-cols-md-4>*{flex:0 0 auto;width:25%}
        .row-cols-md-5>*{flex:0 0 auto;width:20%}
        .row-cols-md-6>*{flex:0 0 auto;width:16.6666666667%}
        .col-md-auto{flex:0 0 auto;width:auto}
        .col-md-1{flex:0 0 auto;width:8.33333333%}
        .col-md-2{flex:0 0 auto;width:16.66666667%}
        .col-md-3{flex:0 0 auto;width:25%}
        .col-md-4{flex:0 0 auto;width:33.33333333%}
        .col-md-5{flex:0 0 auto;width:41.66666667%}
        .col-md-6{flex:0 0 auto;width:50%}
        .col-md-7{flex:0 0 auto;width:58.33333333%}
        .col-md-8{flex:0 0 auto;width:66.66666667%}
        .col-md-9{flex:0 0 auto;width:75%}
        .col-md-10{flex:0 0 auto;width:83.33333333%}
        .col-md-11{flex:0 0 auto;width:91.66666667%}
        .col-md-12{flex:0 0 auto;width:100%}
        .offset-md-0{margin-left:0}
        .offset-md-1{margin-left:8.33333333%}
        .offset-md-2{margin-left:16.66666667%}
        .offset-md-3{margin-left:25%}
        .offset-md-4{margin-left:33.33333333%}
        .offset-md-5{margin-left:41.66666667%}
        .offset-md-6{margin-left:50%}
        .offset-md-7{margin-left:58.33333333%}
        .offset-md-8{margin-left:66.66666667%}
        .offset-md-9{margin-left:75%}
        .offset-md-10{margin-left:83.33333333%}
        .offset-md-11{margin-left:91.66666667%}
    }
    @media (min-width:${minCol.lg}) {
        .col-lg{flex:1 0 0}
        .row-cols-lg-auto>*{flex:0 0 auto;width:auto}
        .row-cols-lg-1>*{flex:0 0 auto;width:100%}
        .row-cols-lg-2>*{flex:0 0 auto;width:50%}
        .row-cols-lg-3>*{flex:0 0 auto;width:33.3333333333%}
        .row-cols-lg-4>*{flex:0 0 auto;width:25%}
        .row-cols-lg-5>*{flex:0 0 auto;width:20%}
        .row-cols-lg-6>*{flex:0 0 auto;width:16.6666666667%}
        .col-lg-auto{flex:0 0 auto;width:auto}
        .col-lg-1{flex:0 0 auto;width:8.33333333%}
        .col-lg-2{flex:0 0 auto;width:16.66666667%}
        .col-lg-3{flex:0 0 auto;width:25%}
        .col-lg-4{flex:0 0 auto;width:33.33333333%}
        .col-lg-5{flex:0 0 auto;width:41.66666667%}
        .col-lg-6{flex:0 0 auto;width:50%}
        .col-lg-7{flex:0 0 auto;width:58.33333333%}
        .col-lg-8{flex:0 0 auto;width:66.66666667%}
        .col-lg-9{flex:0 0 auto;width:75%}
        .col-lg-10{flex:0 0 auto;width:83.33333333%}
        .col-lg-11{flex:0 0 auto;width:91.66666667%}
        .col-lg-12{flex:0 0 auto;width:100%}
        .offset-lg-0{margin-left:0}
        .offset-lg-1{margin-left:8.33333333%}
        .offset-lg-2{margin-left:16.66666667%}
        .offset-lg-3{margin-left:25%}
        .offset-lg-4{margin-left:33.33333333%}
        .offset-lg-5{margin-left:41.66666667%}
        .offset-lg-6{margin-left:50%}
        .offset-lg-7{margin-left:58.33333333%}
        .offset-lg-8{margin-left:66.66666667%}
        .offset-lg-9{margin-left:75%}
        .offset-lg-10{margin-left:83.33333333%}
        .offset-lg-11{margin-left:91.66666667%}
    }
    
    @media (min-width:${minCol.xl}) {
        .col-xl{flex:1 0 0}
        .row-cols-xl-auto>*{flex:0 0 auto;width:auto}
        .row-cols-xl-1>*{flex:0 0 auto;width:100%}
        .row-cols-xl-2>*{flex:0 0 auto;width:50%}
        .row-cols-xl-3>*{flex:0 0 auto;width:33.3333333333%}
        .row-cols-xl-4>*{flex:0 0 auto;width:25%}
        .row-cols-xl-5>*{flex:0 0 auto;width:20%}
        .row-cols-xl-6>*{flex:0 0 auto;width:16.6666666667%}
        .col-xl-auto{flex:0 0 auto;width:auto}
        .col-xl-1{flex:0 0 auto;width:8.33333333%}
        .col-xl-2{flex:0 0 auto;width:16.66666667%}
        .col-xl-3{flex:0 0 auto;width:25%}
        .col-xl-4{flex:0 0 auto;width:33.33333333%}
        .col-xl-5{flex:0 0 auto;width:41.66666667%}
        .col-xl-6{flex:0 0 auto;width:50%}
        .col-xl-7{flex:0 0 auto;width:58.33333333%}
        .col-xl-8{flex:0 0 auto;width:66.66666667%}
        .col-xl-9{flex:0 0 auto;width:75%}
        .col-xl-10{flex:0 0 auto;width:83.33333333%}
        .col-xl-11{flex:0 0 auto;width:91.66666667%}
        .col-xl-12{flex:0 0 auto;width:100%}
        .offset-xl-0{margin-left:0}
        .offset-xl-1{margin-left:8.33333333%}
        .offset-xl-2{margin-left:16.66666667%}
        .offset-xl-3{margin-left:25%}
        .offset-xl-4{margin-left:33.33333333%}
        .offset-xl-5{margin-left:41.66666667%}
        .offset-xl-6{margin-left:50%}
        .offset-xl-7{margin-left:58.33333333%}
        .offset-xl-8{margin-left:66.66666667%}
        .offset-xl-9{margin-left:75%}
        .offset-xl-10{margin-left:83.33333333%}
        .offset-xl-11{margin-left:91.66666667%}
    }
    
    @media (min-width:${minCol.xxl}) {
        .col-xxl{flex:1 0 0}
        .row-cols-xxl-auto>*{flex:0 0 auto;width:auto}
        .row-cols-xxl-1>*{flex:0 0 auto;width:100%}
        .row-cols-xxl-2>*{flex:0 0 auto;width:50%}
        .row-cols-xxl-3>*{flex:0 0 auto;width:33.3333333333%}
        .row-cols-xxl-4>*{flex:0 0 auto;width:25%}
        .row-cols-xxl-5>*{flex:0 0 auto;width:20%}
        .row-cols-xxl-6>*{flex:0 0 auto;width:16.6666666667%}
        .col-xxl-auto{flex:0 0 auto;width:auto}
        .col-xxl-1{flex:0 0 auto;width:8.33333333%}
        .col-xxl-2{flex:0 0 auto;width:16.66666667%}
        .col-xxl-3{flex:0 0 auto;width:25%}
        .col-xxl-4{flex:0 0 auto;width:33.33333333%}
        .col-xxl-5{flex:0 0 auto;width:41.66666667%}
        .col-xxl-6{flex:0 0 auto;width:50%}
        .col-xxl-7{flex:0 0 auto;width:58.33333333%}
        .col-xxl-8{flex:0 0 auto;width:66.66666667%}
        .col-xxl-9{flex:0 0 auto;width:75%}
        .col-xxl-10{flex:0 0 auto;width:83.33333333%}
        .col-xxl-11{flex:0 0 auto;width:91.66666667%}
        .col-xxl-12{flex:0 0 auto;width:100%}
        .offset-xxl-0{margin-left:0}
        .offset-xxl-1{margin-left:8.33333333%}
        .offset-xxl-2{margin-left:16.66666667%}
        .offset-xxl-3{margin-left:25%}
        .offset-xxl-4{margin-left:33.33333333%}
        .offset-xxl-5{margin-left:41.66666667%}
        .offset-xxl-6{margin-left:50%}
        .offset-xxl-7{margin-left:58.33333333%}
        .offset-xxl-8{margin-left:66.66666667%}
        .offset-xxl-9{margin-left:75%}
        .offset-xxl-10{margin-left:83.33333333%}
        .offset-xxl-11{margin-left:91.66666667%}
    }
    
    .d-inline{display:inline}
    .d-inline-block{display:inline-block}
    .d-block{display:block}
    .d-flex{display:flex}
    .d-inline-flex{display:inline-flex}
    .d-none{display:none}
    .flex-fill{flex:1 1 auto}
    .flex-row{flex-direction:row}
    .flex-column{flex-direction:column}
    .flex-row-reverse{flex-direction:row-reverse}
    .flex-column-reverse{flex-direction:column-reverse}
    .flex-grow-0{flex-grow:0}
    .flex-grow-1{flex-grow:1}
    .flex-shrink-0{flex-shrink:0}
    .flex-shrink-1{flex-shrink:1}
    .flex-wrap{flex-wrap:wrap}
    .flex-nowrap{flex-wrap:nowrap} 
    .flex-wrap-reverse{flex-wrap:wrap-reverse}
    .justify-content-start{justify-content:flex-start}
    .justify-content-end{justify-content:flex-end}
    .justify-content-center{justify-content:center}
    .justify-content-between{justify-content:space-between} 
    .justify-content-around{justify-content:space-around}
    .justify-content-evenly{justify-content:space-evenly}
    .align-items-start{align-items:flex-start}
    .align-items-end{align-items:flex-end}
    .align-items-center{align-items:center}
    .align-items-baseline{align-items:baseline}
    .align-items-stretch{align-items:stretch}
    .align-content-start{align-content:flex-start}
    .align-content-end{align-content:flex-end}
    .align-content-center{align-content:center}
    .align-content-between{align-content:space-between}
    .align-content-around{align-content:space-around}
    .align-content-stretch{align-content:stretch}
    .align-self-auto{align-self:auto}
    .align-self-start{align-self:flex-start}
    .align-self-end{align-self:flex-end}
    .align-self-center{align-self:center}
    .align-self-baseline{align-self:baseline}
    .align-self-stretch{align-self:stretch}
    .order-first{order:-1}
    .order-0{order:0}
    .order-1{order:1}
    .order-2{order:2}
    .order-3{order:3}
    .order-4{order:4}
    .order-5{order:5}
    .order-last{order:6} 
    
    @media (min-width:${minCol.sm}) {
        .d-sm-inline{display:inline}
        .d-sm-inline-block{display:inline-block}
        .d-sm-block{display:block}
        .d-sm-flex{display:flex}
        .d-sm-inline-flex{display:inline-flex}
        .d-sm-none{display:none}
        .flex-sm-fill{flex:1 1 auto}
        .flex-sm-row{flex-direction:row}
        .flex-sm-column{flex-direction:column}
        .flex-sm-row-reverse{flex-direction:row-reverse}
        .flex-sm-column-reverse{flex-direction:column-reverse}
        .flex-sm-grow-0{flex-grow:0}
        .flex-sm-grow-1{flex-grow:1}
        .flex-sm-shrink-0{flex-shrink:0}
        .flex-sm-shrink-1{flex-shrink:1}
        .flex-sm-wrap{flex-wrap:wrap}
        .flex-sm-nowrap{flex-wrap:nowrap}
        .flex-sm-wrap-reverse{flex-wrap:wrap-reverse}
        .justify-content-sm-start{justify-content:flex-start}
        .justify-content-sm-end{justify-content:flex-end}
        .justify-content-sm-center{justify-content:center}
        .justify-content-sm-between{justify-content:space-between}
        .justify-content-sm-around{justify-content:space-around}
        .justify-content-sm-evenly{justify-content:space-evenly}
        .align-items-sm-start{align-items:flex-start}
        .align-items-sm-end{align-items:flex-end}
        .align-items-sm-center{align-items:center}
        .align-items-sm-baseline{align-items:baseline}
        .align-items-sm-stretch{align-items:stretch}
        .align-content-sm-start{align-content:flex-start}
        .align-content-sm-end{align-content:flex-end}
        .align-content-sm-center{align-content:center}
        .align-content-sm-between{align-content:space-between}
        .align-content-sm-around{align-content:space-around}
        .align-content-sm-stretch{align-content:stretch}
        .align-self-sm-auto{align-self:auto}
        .align-self-sm-start{align-self:flex-start}
        .align-self-sm-end{align-self:flex-end}
        .align-self-sm-center{align-self:center}
        .align-self-sm-baseline{align-self:baseline}
        .align-self-sm-stretch{align-self:stretch}
        .order-sm-first{order:-1}
        .order-sm-0{order:0}
        .order-sm-1{order:1}
        .order-sm-2{order:2}
        .order-sm-3{order:3}
        .order-sm-4{order:4}
        .order-sm-5{order:5}
        .order-sm-last{order:6}
    }
    @media (min-width:${minCol.md}) {
        .d-md-inline{display:inline}
        .d-md-inline-block{display:inline-block}
        .d-md-block{display:block}
        .d-md-flex{display:flex}
        .d-md-inline-flex{display:inline-flex}
        .d-md-none{display:none}
        .flex-md-fill{flex:1 1 auto}
        .flex-md-row{flex-direction:row}
        .flex-md-column{flex-direction:column}
        .flex-md-row-reverse{flex-direction:row-reverse}
        .flex-md-column-reverse{flex-direction:column-reverse}
        .flex-md-grow-0{flex-grow:0}
        .flex-md-grow-1{flex-grow:1}
        .flex-md-shrink-0{flex-shrink:0}
        .flex-md-shrink-1{flex-shrink:1}
        .flex-md-wrap{flex-wrap:wrap}
        .flex-md-nowrap{flex-wrap:nowrap}
        .flex-md-wrap-reverse{flex-wrap:wrap-reverse}
        .justify-content-md-start{justify-content:flex-start}
        .justify-content-md-end{justify-content:flex-end}
        .justify-content-md-center{justify-content:center}
        .justify-content-md-between{justify-content:space-between}
        .justify-content-md-around{justify-content:space-around}
        .justify-content-md-evenly{justify-content:space-evenly}
        .align-items-md-start{align-items:flex-start}
        .align-items-md-end{align-items:flex-end}
        .align-items-md-center{align-items:center}
        .align-items-md-baseline{align-items:baseline}
        .align-items-md-stretch{align-items:stretch}
        .align-content-md-start{align-content:flex-start}
        .align-content-md-end{align-content:flex-end}
        .align-content-md-center{align-content:center}
        .align-content-md-between{align-content:space-between}
        .align-content-md-around{align-content:space-around}
        .align-content-md-stretch{align-content:stretch}
        .align-self-md-auto{align-self:auto}
        .align-self-md-start{align-self:flex-start}
        .align-self-md-end{align-self:flex-end}
        .align-self-md-center{align-self:center}
        .align-self-md-baseline{align-self:baseline}
        .align-self-md-stretch{align-self:stretch}
        .order-md-first{order:-1}
        .order-md-0{order:0}
        .order-md-1{order:1}
        .order-md-2{order:2}
        .order-md-3{order:3}
        .order-md-4{order:4}
        .order-md-5{order:5}
        .order-md-last{order:6}
    }
    @media (min-width:${minCol.lg}) {
        .d-lg-inline{display:inline}
        .d-lg-inline-block{display:inline-block}
        .d-lg-block{display:block}
        .d-lg-flex{display:flex}
        .d-lg-inline-flex{display:inline-flex}
        .d-lg-none{display:none}
        .flex-lg-fill{flex:1 1 auto}
        .flex-lg-row{flex-direction:row}
        .flex-lg-column{flex-direction:column}
        .flex-lg-row-reverse{flex-direction:row-reverse}
        .flex-lg-column-reverse{flex-direction:column-reverse}
        .flex-lg-grow-0{flex-grow:0}
        .flex-lg-grow-1{flex-grow:1}
        .flex-lg-shrink-0{flex-shrink:0}
        .flex-lg-shrink-1{flex-shrink:1}
        .flex-lg-wrap{flex-wrap:wrap}
        .flex-lg-nowrap{flex-wrap:nowrap}
        .flex-lg-wrap-reverse{flex-wrap:wrap-reverse}
        .justify-content-lg-start{justify-content:flex-start}
        .justify-content-lg-end{justify-content:flex-end}
        .justify-content-lg-center{justify-content:center}
        .justify-content-lg-between{justify-content:space-between}
        .justify-content-lg-around{justify-content:space-around}
        .justify-content-lg-evenly{justify-content:space-evenly}
        .align-items-lg-start{align-items:flex-start}
        .align-items-lg-end{align-items:flex-end}
        .align-items-lg-center{align-items:center}
        .align-items-lg-baseline{align-items:baseline}
        .align-items-lg-stretch{align-items:stretch}
        .align-content-lg-start{align-content:flex-start}
        .align-content-lg-end{align-content:flex-end}
        .align-content-lg-center{align-content:center}
        .align-content-lg-between{align-content:space-between}
        .align-content-lg-around{align-content:space-around}
        .align-content-lg-stretch{align-content:stretch}
        .align-self-lg-auto{align-self:auto}
        .align-self-lg-start{align-self:flex-start}
        .align-self-lg-end{align-self:flex-end}
        .align-self-lg-center{align-self:center}
        .align-self-lg-baseline{align-self:baseline}
        .align-self-lg-stretch{align-self:stretch}
        .order-lg-first{order:-1}
        .order-lg-0{order:0}
        .order-lg-1{order:1}
        .order-lg-2{order:2}
        .order-lg-3{order:3}
        .order-lg-4{order:4}
        .order-lg-5{order:5}
        .order-lg-last{order:6}
    }
    @media (min-width:${minCol.xl}) {
        .d-xl-inline{display:inline}
        .d-xl-inline-block{display:inline-block}
        .d-xl-block{display:block}
        .d-xl-flex{display:flex}
        .d-xl-inline-flex{display:inline-flex}
        .d-xl-none{display:none}
        .flex-xl-fill{flex:1 1 auto}
        .flex-xl-row{flex-direction:row}
        .flex-xl-column{flex-direction:column}
        .flex-xl-row-reverse{flex-direction:row-reverse}
        .flex-xl-column-reverse{flex-direction:column-reverse}
        .flex-xl-grow-0{flex-grow:0}
        .flex-xl-grow-1{flex-grow:1}
        .flex-xl-shrink-0{flex-shrink:0}
        .flex-xl-shrink-1{flex-shrink:1}
        .flex-xl-wrap{flex-wrap:wrap}
        .flex-xl-nowrap{flex-wrap:nowrap}
        .flex-xl-wrap-reverse{flex-wrap:wrap-reverse}
        .justify-content-xl-start{justify-content:flex-start}
        .justify-content-xl-end{justify-content:flex-end}
        .justify-content-xl-center{justify-content:center}
        .justify-content-xl-between{justify-content:space-between}
        .justify-content-xl-around{justify-content:space-around}
        .justify-content-xl-evenly{justify-content:space-evenly}
        .align-items-xl-start{align-items:flex-start}
        .align-items-xl-end{align-items:flex-end}
        .align-items-xl-center{align-items:center}
        .align-items-xl-baseline{align-items:baseline}
        .align-items-xl-stretch{align-items:stretch}
        .align-content-xl-start{align-content:flex-start}
        .align-content-xl-end{align-content:flex-end}
        .align-content-xl-center{align-content:center}
        .align-content-xl-between{align-content:space-between}
        .align-content-xl-around{align-content:space-around}
        .align-content-xl-stretch{align-content:stretch}
        .align-self-xl-auto{align-self:auto}
        .align-self-xl-start{align-self:flex-start}
        .align-self-xl-end{align-self:flex-end}
        .align-self-xl-center{align-self:center}
        .align-self-xl-baseline{align-self:baseline}
        .align-self-xl-stretch{align-self:stretch}
        .order-xl-first{order:-1}
        .order-xl-0{order:0}
        .order-xl-1{order:1}
        .order-xl-2{order:2}
        .order-xl-3{order:3}
        .order-xl-4{order:4}
        .order-xl-5{order:5}
        .order-xl-last{order:6}
    }
    @media (min-width:${minCol.xxl}) {
        .d-xxl-inline{display:inline}
        .d-xxl-inline-block{display:inline-block}
        .d-xxl-block{display:block}
        .d-xxl-flex{display:flex}
        .d-xxl-inline-flex{display:inline-flex}
        .d-xxl-none{display:none}
        .flex-xxl-fill{flex:1 1 auto}
        .flex-xxl-row{flex-direction:row}
        .flex-xxl-column{flex-direction:column}
        .flex-xxl-row-reverse{flex-direction:row-reverse}
        .flex-xxl-column-reverse{flex-direction:column-reverse}
        .flex-xxl-grow-0{flex-grow:0}
        .flex-xxl-grow-1{flex-grow:1}
        .flex-xxl-shrink-0{flex-shrink:0}
        .flex-xxl-shrink-1{flex-shrink:1}
        .flex-xxl-wrap{flex-wrap:wrap}
        .flex-xxl-nowrap{flex-wrap:nowrap}
        .flex-xxl-wrap-reverse{flex-wrap:wrap-reverse}
        .justify-content-xxl-start{justify-content:flex-start}
        .justify-content-xxl-end{justify-content:flex-end}
        .justify-content-xxl-center{justify-content:center}
        .justify-content-xxl-between{justify-content:space-between}
        .justify-content-xxl-around{justify-content:space-around}
        .justify-content-xxl-evenly{justify-content:space-evenly}
        .align-items-xxl-start{align-items:flex-start}
        .align-items-xxl-end{align-items:flex-end}
        .align-items-xxl-center{align-items:center}
        .align-items-xxl-baseline{align-items:baseline}
        .align-items-xxl-stretch{align-items:stretch}
        .align-content-xxl-start{align-content:flex-start}
        .align-content-xxl-end{align-content:flex-end}
        .align-content-xxl-center{align-content:center}
        .align-content-xxl-between{align-content:space-between}
        .align-content-xxl-around{align-content:space-around}
        .align-content-xxl-stretch{align-content:stretch}
        .align-self-xxl-auto{align-self:auto}
        .align-self-xxl-start{align-self:flex-start}
        .align-self-xxl-end{align-self:flex-end}
        .align-self-xxl-center{align-self:center}
        .align-self-xxl-baseline{align-self:baseline}
        .align-self-xxl-stretch{align-self:stretch}
        .order-xxl-first{order:-1}
        .order-xxl-0{order:0}
        .order-xxl-1{order:1}
        .order-xxl-2{order:2}
        .order-xxl-3{order:3}
        .order-xxl-4{order:4}
        .order-xxl-5{order:5}
        .order-xxl-last{order:6}
    }
    
    // global style ***************************************
    
    :root {
        --dark: #020404; // MF Dark
        --gray: #363738; // MF Gray
        --gray_op4: #171818; // MF Gray
        --lgray: #828284; // MF Light Gray
        --slgray: #DFDED7; // MF Super Light Gray
        --paper: #F2F1E8; // MF Paper
        --red : #E32839; //MF Red
        --green: #00D8A9; // MF Green
        --blue: #00A0E2; // MF Blue 
        --yellow: #FFA400 // MF Yellow
    }
        
    .ovh {
        overflow: hidden;
    }
    img {   
        max-width: 100%;
    } 
    /* width */
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    // #86644B
    /* Track */
    ::-webkit-scrollbar-track {
      background-color: var(--slgray);  
    } 
     
    /* Handle */ 
    ::-webkit-scrollbar-thumb {
        background: var(--blue);
        //background: var(--lgray);
    }
    
    html, body {
        margin: 0;
        padding: 0;
    }
    
    * {
      outline: none;
    }
    
    strong {
       font-weight: 800;
    }
    
    html {
        //font-size: 50%;
        --bs-gutter-x: 2rem;
        font-size: 62.5%;
        @media (min-width: ${minCol.sm} ) { 
    
        }
        
        @media (min-width: ${minCol.md} ) {
            
        }
        
        @media (min-width: ${minCol.lg} ) {
    
        }
        
        @media (min-width: ${minCol.xl}) {
            
        }
         
        @media (min-width: ${minCol.xxl}) {
            
        } 
    }
     
    body {
        font-family: Geologica;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.25;
        text-underline-position: from-font; 
        text-decoration-skip-ink: none;
        color: #363738;
        background-color: var(--paper);
    }
    
    .body.colorRevers {
        color: var(--paper);
        a { 
            color: var(--paper);
        }
        background-color: var(--dark);
        .btn.btn1 {
            background-color: rgba(0,0,0,0);
            .iconBG:before {
                filter: brightness(0) invert(1); 
            }
        }
        .border-hor:before, .border-hor:after, .border-ver:before, .border-ver:after {
            background-color: var(--paper);
        }
        .row-section>* {
            border-color: var(--paper);
        }
    }
    .template-page404 {
        height: 100vh;
        .container, .wrapMain, .main {
            height: 100%;
        }
        .wrapMain { 
            display: flex;
            flex-direction: column;
        }
        .main {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 1.8rem;
            &:after {
                content: '';
                display: block;
                position: absolute;
                left: -2000px;
                right: -2000px;
                background-color: var(--paper);
                height: 2px;
                bottom: 1.8rem;
                top: auto;
            }
        }
    }    
     
    .wrapContainer {
        max-width: 90rem;
        margin-left: auto;
        margin-right: auto;
    }
     
    
    // Fonts ************************************
    
    .fw-3, .st-3, .st-4 { font-weight: 300;}
    .fw-4 { font-weight: 400;}
    .fw-6, .h4, .h5 { font-weight: 600;}
    .fw-8, strong, .h1, .h2, .h3 { font-weight: 800;}
    .fw-9 { font-weight: 900; }
    
    h1,h2,h3,h4,h5,h6 {
        margin: 0;
    }
    
    // Heading
    .h1 { font-size: 5.8rem; line-height: 1;}
    .h2 { 
        font-size: 4.8rem;
        @media (max-width: ${maxCol.sm}) {
            font-size: 4.2rem;
        }
    }
    .h3 { 
        font-size: 3.2rem;
        @media (max-width: ${maxCol.sm}) {
            font-size: 2.4rem;
        }
    }
    .h4 { font-size: 2.8rem;} 
    .h5 { font-size: 2.4rem;}
    
    .h2,.h3,.h4,.h5  {
        line-height: 1.25;
    }
    
    // Subtitle
    .st1 { font-size: 2rem; line-height: 1.1;} 
    .st2 { font-size: 1.8rem; } 
    .st3 { font-size: 1.6rem; }  // Body Text 
    .st4 { font-size: 1.4rem;} 
    .st5 { font-size: 1.2rem; }  // Note
    
    .st2,.st3,.st4,.st5  { 
        line-height: 1.25;
    }
    
    // color ************************************
    
    
    
    .box {
        width: 20rem;
        height: 7.5rem;
        color: #fff;
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bgc1 { background-color: var(--dark); }
    .bgc2 { background-color: var(--gray); }
    .bgc3 { background-color: var(--lgray); }
    .bgc4 { background-color: var(--slgray); }
    .bgc5 { background-color: var(--paper); }
    .bgc6 { background-color: var(--red); }
    .bgc7 { background-color: var(--green); }
    .bgc8 { background-color: var(--blue); }
    .bgc9 { background-color: var(--yellow); }
     
    .tc1 { color: var(--dark); }
    .tc2 { color: var(--gray); }
    .tc3 { color: var(--lgray); }
    .tc4 { color: var(--slgray); }
    .tc5 { color: var(--paper); }
    .tc6 { color: var(--red); }
    .tc7 { color: var(--green); }
    .tc8 { color: var(--blue); }
    .tc9 { color: var(--yellow); }
    
    // Badges ************************************
    
    .bds {
        font-size: 1.2rem;
        line-height: 1; 
        text-align: center;
        text-transform: uppercase; 
        padding: 0.8rem 2.45rem; 
        border-radius: 5.5rem;
        display: inline-flex;
        color: var(--paper);
        &.min {
            padding: 0.5rem 1.25rem;
        }
    }
    
    // Icons ************************************
    
    .icon24:before {
        width: 2.4rem;
        height: 2.4rem;
        display: inline-flex;
    }
    .icon18:before {
        width: 1.8rem;
        height: 1.8rem;
        display: inline-flex;
    }
    .iconBG {
        display: flex;
        align-items: center;
        &.iconWhite {
            filter: brightness(0) invert(1);
        }
    }
    .iconBG:before {
        display: inline-flex;
        content:'';
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        //margin-right: 2rem;
        z-index: 2;
    }
    
     
    
    // Buttons ************************************
    
    .btn {
        //margin: 0.4rem;
        display: inline-flex;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 900;
        line-height: 1;
        position: relative;
        overflow: hidden;
        text-decoration: none;
        span {
            z-index: 2;
        }
        &:after {
            content: '';
            position: absolute;
            margin: auto;
            z-index: 1;
            display: block;
            top: -200%;
        }
        &.btn1 {
            border: 2px solid var(--gray);
            padding: 1.4rem 7rem;
            background-color: var(--paper);
            font-size: 1.6rem;
            color: var(--dark);
            &:after {
                left: 0;
                right: 0;
                bottom: 0;
                width: calc(100% - 0.4rem);
                height: calc(100% - 0.4rem);
                background-color: var(--blue);
                border: 0.2rem solid rgba(0,0,0,0);
            }
            &:hover, &.pressed {
                &:after {
                    top: 0;
                }
            }
            &.select, &[aria-current="page"] {
                &:after {
                    top: 0;
                    background: none;
                    border: 0.2rem solid var(--blue);
                }
            }
            &.disable { 
                border-color: var(--lgray);
                color: var(--lgray);
                cursor: default;
                &:after {
                    display: none;
                }
            }
        } 
        &.btn2 {
            //border: 2px solid var(--gray);
            border: none;
            padding: 1.6rem 7.2rem;
            background-color: var(--gray);
            color: var(--paper);
            font-size: 1.6rem;
            &:after {
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255,255,255,0.2);
            } 
            &:hover, &.pressed {
                &:after {
                    top: 0;
                }
            }
            &.select, &[aria-current="page"] {
                &:after {
                    left: 0;
                    top: 0;
                    width: calc(100% - 0.8rem);
                    height: calc(100% - 0.8rem);
                    background: none;
                    border: 0.2rem solid var(--blue);
                }
            }
            &.disable {
                border-color: var(--slgray); 
                background-color: var(--slgray);
                color: var(--lgray);
                cursor: default; 
                &:after {
                    display: none;
                }
            }
        }
        &.btn3 {
            //border: 2px solid var(--gray);
            border: none;
            padding: 1.6rem 7.2rem;
            background-color: var(--blue);
            color: var(--paper);
            font-size: 1.6rem;
            &:after {
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255,255,255,0.2);
            }
            &:hover, &.pressed {
                &:after {
                    top: 0;
                }
            }
            &.select, &[aria-current="page"] {
                &:after {
                    left: 0;
                    top: 0;
                    width: calc(100% - 0.8rem);
                    height: calc(100% - 0.8rem);
                    background: none;
                    border: 0.2rem solid var(--paper);
                }
            }
        }
        &.btn4 { 
            background-color: var(--slgray);
            border-radius: 3.2rem;
            display: inline-flex;
            align-items: center;
            font-weight: 400;
            text-transform: none;
            padding: 0.85rem 2.4rem 0.85rem 2.4rem;
            .iconBG {
                margin-left: 0.8rem;
            }
            &:hover {
                background-color: var(--blue);
            }
        }
    
        &.btn5 {
            display: inline-flex;
            align-items: center;
            text-transform: none;
            //padding: 1.2rem 2.4rem 1.2rem 2.4rem;
            font-weight: 400;
            .iconBG {
                margin-left: 1.2rem;
            }
            &:hover { 
                color: var(--blue);
            }
        }
        &.btn6 {
            //border: 2px solid var(--gray);
            border: none;
            padding: 1.6rem 7.2rem;
            background-color: var(--dark);
            color: var(--paper);
            font-size: 1.6rem;
            &:after {
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255,255,255,0.2);
            }
            &:hover, &.pressed {
                &:after {
                    top: 0;
                }
            }
            &.select, &[aria-current="page"] {
                &:after {
                    left: 0;
                    top: 0;
                    width: calc(100% - 0.8rem);
                    height: calc(100% - 0.8rem);
                    background: none;
                    border: 0.2rem solid var(--blue);
                }
            }
            &.disable {
                border-color: var(--slgray);
                background-color: var(--slgray);
                color: var(--lgray);
                cursor: default;
                &:after {
                    display: none;
                }
            }
        }
    
    
        &.btnIcon {
            width: 5.6rem;
            height: 5.6rem;
            padding: 0;
            .iconBG {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 2;
            }
            &.disable {
                .iconBG {
                    opacity: 0.6;
                }
            }
        }
        
        &.btnIconSquare {
            .iconBG {
                border-radius: 50%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 4.6rem;
                height: 4.6rem;
                opacity: 1;
            }
            &:hover, &.pressed {
                .iconBG {
                    background-color: var(--blue);
                    &:before {
                        filter: brightness(0) invert(1);
                    }
                }
                
            }
        }
        &.borderNone {
            border: none;
        }
        &.bgNone {
           background: none;
        }
        
        &.cursorNone {
            cursor: default;
        }
         
    }
    
    
    // form input  ************************************
     
    
    .wrap-form {
        .formTitle {
            &.style-1 {
                color: var(--blue);
                margin-bottom: 2rem;
                line-height: 1.25;
            }
        }
    }
    .wrap-input {
        margin-bottom: 1.6rem;
        label {
            font-size: 1.4rem;
            font-weight: 300;
            line-height: 1;
            text-align: left;
            display: block;
            color: var(--lgray);
            margin-bottom: 1.4rem;
        }
        input, textarea {
            display: block;
            font-size: 1.6rem;
            line-height: 1.25;
            background-color: var(--slgray);
            color: var(--gray);
            border: 2px solid var(--slgray);
            width: 100%;
            height: 5.6rem;
            padding: 0 2.4rem; 
            &::placeholder {
                color: var(--lgray); 
            }
            &:active, &:focus {
                border: 2px solid var(--blue);
            }
        }
        &.error {
            label {
                color: var(--red);
            }
            input {
                border-color: var(--red);   
            }
        }
    
        textarea {
            height: 11.2rem;
            padding: 2.4rem;
        }
        
        &.searchBox {
            position: relative;
            border: 2px solid var(--lgray);
            display: flex;
            align-items: center;
            .btn {
                margin: 0;
                min-width: 5.8rem;
            }
            .iconBG:before {
                filter: brightness(0) invert(1);
            }
    
            .btn {
                border-left: 2px solid var(--lgray);
            }
            
            &.black { 
                input {
                    background-color: var(--gray_op4);
                    border-color: var(--gray_op4);
                    color: var(--paper);
                } 
                border: 2px solid var(--gray);
                .btn {
                    border-left: 2px solid var(--gray);
                    background-color: var(--dark);
                }
            }
        }
    }
    
    // link  ************************************
    
    
    a {
        color: inherit;
        &:hover {
            color: var(--blue);
        }
    }
    
    .link {
        cursor: pointer;
        text-transform: none;
        &.link-1, &.link-2 {
            width: fit-content;
            position: relative;
            overflow: hidden;
            display: flex;
            padding-bottom: 1rem;
            &:after, &:before {
                content: "";
                display: block;
                position: absolute;
                height: 2px;
                background-color: var(--gray);
                bottom: 0rem;
            }
            &:before {
                transform: translate3d(-102%, 0, 0);
                transition: 1000ms transform cubic-bezier(0.23, 1, 0.32, 1);
            }
            &:after {
                transition: 1000ms transform cubic-bezier(0.23, 1, 0.32, 1) 200ms;
            }
        
            &:hover {
                &:before {
                    transform: translate3d(0%, 0, 0);
                    transition: 1000ms transform cubic-bezier(0.23, 1, 0.32, 1) 200ms;
                }
                &:after {
                    transform: translate3d(calc(101% + 20px), 0, 0);
                    transition: 1000ms transform cubic-bezier(0.23, 1, 0.32, 1);
                }
            }
        }
        
        &.link-1 {
            &:after, &:before {
                width: 50%;
            }
            &:after {
                display: none;
            }
        }
        &.link-2 {
            &:after, &:before {
                width: 100%;
            }
            &:before {
                display: none;
            }
            &:hover {
                &:after {
                    transform: translate3d(calc(30%), 0, 0);
                    transition: 1000ms transform cubic-bezier(0.23, 1, 0.32, 1);
                }
            }
        }
        &.link-3 {
            color: inherit;
            &:hover {
                color: var(--blue);
            }
        }
    }
    
    // css  ************************************
     
    .anim, a, .a, .btn, .btn:before, .btn:after, .iconBG, .iconBG:before, .link, input, textarea {
        transition: all 0.5s ease;
        //transition: 1000ms transform cubic-bezier(0.23, 1, 0.32, 1) 200ms;
        transition: 500ms all 200ms; 
    }
    
    p {
        margin: 0 0 2rem;
    }
    
    .grey {
        //filter: grayscale(100%);
    }
    .grey_off {
        filter: none;
    }
    .pos {
        position: relative;
    }
    .z-in-1 {
        z-index: 1;
    }
    .z-in-2 {
        z-index: 2;
    }
    .h100 {
        height: 100%;
    }
    .h50 {
        height: 50%;
    }
    .mh100 {
        min-height: 100%;
    }
    .mh50 {
        min-height: 50%;
    }
    .w100 {
        width: 100%;
    }
    .ul-clear {
        margin: 0;
        padding: 0;
    }
    .ul-clear li::marker {
        font-size: 0;
    }
    .p-0 {
        padding:0;
    }
    .pr-0 {
        padding-right: 0 !important;
    }
    .pl-0 {
        padding-right: 0 !important;
    }
    
    .pt-0 {
        padding-top: 0 !important;
    }
    .pb-0 {
        padding-bottom: 0 !important;
    }
    
    .m-0 {
        margin:0;
    }
    
    .ml-auto {
        margin-left: auto;
    }
    
    .mr-auto {
        margin-right: auto;
    }
    
    .text-center {
        text-align: center;
    }
    .text-right {
        text-align: right;
    }
    .body {
        overflow: hidden;
    }
    .ttu {
        text-transform: uppercase;
    }
    
     
    // border-line-block  ************************************
    
    .row-0 {
        --bs-gutter-x: 0 !important;
    }
    .row-section {
        & > * {
            border-right: 2px solid var(--gray);
            &:last-child {
                border-right: 2px solid rgba(0,0,0,0);
            }
        }
        &.color-white {
            & > * {
                border-right: 2px solid var(--paper);
            }
        }
    }
    
    // wrapMain
    
    .wrapMain {
        min-height: 100vh; 
    }
    
    .border-ver {
        position: relative;
        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            top: -1000px;
            bottom: 0px;
            left: 0;
            background-color: var(--gray);
            width: 2px;
        }
        &:after {
            left: auto; 
            right: 0;
        }
        &.color-white {
            &:before, &:after {
                background-color: var(--paper);
            }
        }
    }
     
    .border-hor {
        position: relative;
        padding-top: 2px;
        padding-bottom: 2px;
        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            left: -2000px; 
            right: -2000px;
            background-color: var(--gray);
            height: 2px;
            top: 0;
        }
        &:after {
             bottom: 0;
            top: auto;
        }
        &.color-white {
            &:before, &:after {
                background-color: var(--paper);
            }
        }
    }
    
    .border-hor-bottom {
        padding-bottom: 2px;
    } 
    .border-hor-top {
        padding-top: 2px;
    }
    .border-hor-bottom, .border-hor-top {
        position: relative;
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: -2000px;
            right: -2000px;
            background-color: var(--gray);
            height: 2px;
            top: 0;
        }
        &:after {
            bottom: 0;
            top: auto;
        }
        &.color-white {
            &:after {
                background-color: var(--paper);
            }
        }
    }
    
    .border-hor-top {
        &:after {
            bottom: auto;
            top: 0;
        }
    }
    
    
    
    // menu ************************************
     
    .wrapMenu {
        [aria-current="page"] {
            color: var(--blue);
        }
        ul {
            margin: 0;
            padding: 0;
        }
        a {
            text-decoration: none;
        }
        ::marker {
            font-size: 0;
        }
        &.style-1 {
            height: 100%;
            padding: 0 4.8rem;
            li {
                padding-right: 4.2rem;
            }
            @media (max-width:${maxCol.xl}) {
                padding: 0 2.4rem; 
                li {
                    padding-right: 2.1rem;
                }
            }
        }
        &.style-2 {
            button {
                width: 100%;
                background: none;
                border: none;
                color: var(--paper);
                text-align: left;
                font-size: 1.6rem;
                padding-left: 0;
            }
            li {
                margin-bottom: 3.2rem;
            }
            .ul-sub {
                position: absolute;
                top: 2rem;
                width: calc(100% - 4rem);
                left: 2rem;
                bottom: 2rem;
                z-index: 1;
                padding-top: 5rem;
                background-color: var(--dark);
                &.open {
                    left: 2rem;
                }
                &.close {
                    left: -200%;
                }
            }
            .a {
                display: block;
                position: relative;
                min-height: 2.4rem;
                cursor: pointer;
                font-size: 1.6rem;
            }
    
            .return:before, .sub > .a:before {
                background-image: url(${Icons24_31});
                content: '';
                display: block;
                width: 2.4rem;
                height: 2.4rem;
                position: absolute;
                top: 0;
                bottom: 0;
            }
            .sub > .a {
                &:before {
                    filter: brightness(0) invert(1);
                    right: 0;
                }
            }
    
            .return {
                width: 100%;
                border: none;
                background: none;
                position: relative;
                min-height: 2.4rem;
                display: flex;
                align-items: center;
                padding-left: 5.6rem;
                color: var(--lgray);
                cursor: pointer;
                margin-bottom: 5.4rem;
                &:before {
                    transform: rotate(180deg);
                    left: 0;
                    right: auto;
                }
            }
        }
    }
    
    .iconBG32:before  { background-image: url(${Icons24_32}); }
    
    .wrapTitleBlock {
        .BlockTitle {
            font-size: 3rem; 
            line-height: 1.25;
            padding-left: 4.2rem;
            padding-right: 4.2rem;
            text-decoration: none;
            @media (max-width: ${maxCol.sm}) {
                padding-right: 0;
                padding-left: 2.4rem;
            }
        }
        .wrapHeaderIcon {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .more {
            padding-right: 2.4rem;
        }
    }
`;

export default GlobalStyles;