// src/components/Layout.js
import React from "react"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import GlobalStyles from "../assets/styles/GlobalStyles"

const Layout = (props) => {

    const {element , children} = props

    console.log('>>>>>>>>>>', props)

    const class_tempate = (element) => {
        const is404 = element?.props?.location?.key
        const templateName = element?.props?.pageContext.templateFix;

        if( templateName ) {
            return templateName;
        } else {
            if ( is404 === 'initial') {
                return 'page404'
            } else {
                return 'customPage'
            }
        }
        // return element.props.pageContext.template?.templateName || 'page404';
    }

    console.log("Layout >>>>>>>>>", class_tempate(element), props )

    return (
        <div className={`body template-${class_tempate(element)} ${class_tempate(element) === 'page404' ? 'colorRevers' : ''}`}>
            {/*<GlobalStyles/>*/}
            <div className="container">
                <div className="wrapMain border-ver">
                    <Header colorRevers={class_tempate(element) === 'page404' ? 'colorRevers' : 'colorNormal'} />
                    <div className='main'>
                        {children}
                    </div>
                </div>
            </div>

            {class_tempate(element) === 'page404' ? '' : (<Footer data={element?.props}/>)}
        </div>
    )
}

export default Layout